import { CompanyAvatar, Icon, TextLink } from "@components/library";
import { COLORS, FONTS, WINDOW_DIMENSIONS } from "@constants";
import fallbackBannerImage from "@images/backgrounds/fallback_application.png";
import { RootState } from "@redux/store";
import { CompanyPartial, ConfidentialCompany, RfpPageRequest } from "@tsTypes/index";
import { Link } from "react-router-dom";
import styled from "styled-components";
// @ts-ignore
import { CompanyVerificationStatus } from "@tsTypes/companies.js.erb";
import { RequestForExpertsShowResponse } from "@tsTypes/request_for_experts/show";
import { useSelector } from "react-redux";

interface Props {
  request: RfpPageRequest | RequestForExpertsShowResponse;
  asPreview?: boolean;
}
export default function Banner({ request, asPreview = false }: Props) {
  const isTablet = useSelector((state: RootState) => state.window.isTablet);

  const isCompanyPrivate = "private_alias" in request.company || request.confidential;

  return (
    <Container
      backgroundImageUrl={request.commercial_application?.banner_image.url ?? fallbackBannerImage}
      asPreview={asPreview}
    >
      <CompanyContainer
        as={isCompanyPrivate && "div"}
        to={!isCompanyPrivate && `/company/${(request.company as CompanyPartial).identifier}`}
        asPreview={asPreview}
      >
        <CompanyAvatar
          imageUrl={"avatar" in request.company ? request.company.avatar.url : null}
          isPrivate={isCompanyPrivate}
          size={isTablet ? "sm" : "lg"}
        />
        {isCompanyPrivate
          ? (request.company as ConfidentialCompany).private_alias
          : (request.company as CompanyPartial).company_name}
        {request.company.verification_status === CompanyVerificationStatus.VERIFIED && (
          <Icon name="Verified" size="sm" margin="0 0 0 -12px" color={COLORS.BLUE_LIGHT_650} />
        )}
      </CompanyContainer>
      <Title title={request.title} asPreview={asPreview}>
        {request.title}
      </Title>
      {request.commercial_application && (
        <TextLink
          to={`/application/${request.commercial_application.slug}`}
          font={isTablet ? FONTS.SEMIBOLD_2 : FONTS.SEMIBOLD_1}
          color={COLORS.WHITE}
          margin="auto 0 0"
        >
          {request.commercial_application.name}
        </TextLink>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 400px;
  padding: 32px;
  background: url(${({ backgroundImageUrl }) => backgroundImageUrl});
  background-size: cover;
  border-radius: 12px;
  ${({ asPreview }) =>
    !asPreview &&
    `
    ${WINDOW_DIMENSIONS.TABLET_MEDIA_QUERY} {
      height: 240px;
      padding: 32px;
    }
  `}
`;
const CompanyContainer = styled(Link)`
  display: flex;
  align-items: center;
  gap: 16px;
  color: ${COLORS.WHITE};
  ${FONTS.HEADING_5_BOLD}
  &:hover {
    color: ${COLORS.WHITE};
    text-decoration: none;
  }
  ${({ asPreview }) =>
    !asPreview &&
    `
    ${WINDOW_DIMENSIONS.TABLET_MEDIA_QUERY} {
      ${FONTS.BOLD_2};
    }
  `}
`;
const Title = styled.div`
  max-width: min(85%, 1200px);
  ${({ title }) => {
    if (title.length > 50) return FONTS.HEADING_JUMBO;
    return FONTS.HEADING_BANNER;
  }};
  color: ${COLORS.WHITE};
  ${({ asPreview, title }) =>
    !asPreview &&
    `
      ${WINDOW_DIMENSIONS.DESKTOP_MEDIA_QUERY} {
        max-width: unset;
        ${(() => {
          if (title.length > 50) return FONTS.HEADING_1_BOLD;
          return FONTS.HEADING_JUMBO;
        })()};
      }
      ${WINDOW_DIMENSIONS.TABLET_MEDIA_QUERY} {
        ${(() => {
          if (title.length > 50) return FONTS.HEADING_5_SEMIBOLD;
          return FONTS.HEADING_4_BOLD;
        })()};
      }
  `}
`;
